"use client";

import { useRouter } from "next/router";
import { CosmosButton } from "@cosmos/web/react";
import { RbVideo } from "../../rb-video/rb-video";
import classnames from "classnames/bind";
import { BlocksProductStage } from "../../../__generated__/contentful-types";
import { ContentfulResponsiveImage } from "../../contentful-responsive-image/contentful-responsive-image";
import { useViewportDimensions } from "../../../helpers/useViewportDimensions";
import { DietaryMarks } from "../../dietary-marks/dietary-marks";
import { Kicker } from "../../kicker/kicker";
import styles from "./product-stage.module.css";

const cx = classnames.bind(styles);

const MOBILE_BREAKPOINT = 500;

export const ProductStage = ({ block }: { block: BlocksProductStage }) => {
  const { asPath: route } = useRouter();
  const { viewportWidth } = useViewportDimensions();

  return (
    <div className={cx("container")}>
      <div className={cx("stage")}>
        <div className={cx("stage-inner")}>
          <div className={cx("stage-main")}>
            <div className={cx("text")}>
              {block.kicker && (
                <h1 className={cx("kicker-wrapper")}>
                  <Kicker
                    className={cx("kicker")}
                    kind="underlined"
                    text={block.kicker}
                  />
                </h1>
              )}
              {/* If kicker exists, it should be the H1 */}
              {block.kicker ? (
                <p className={cx("title")}>{block.title}</p>
              ) : (
                <h1 className={cx("title")}>{block.title}</h1>
              )}
              {block.subtitle && (
                <p className={cx("subtitle")}>{block.subtitle}</p>
              )}
              <div className={cx("button-wrapper")}>
                {block.buttonUrl && block.buttonText && (
                  <CosmosButton
                    appearance="dark"
                    href={block.buttonUrl}
                    onClick={(element) => {
                      if (block.buttonUrl?.includes("#")) {
                        element.preventDefault();
                        scrollBy(block.buttonUrl.substring(1));
                      }
                    }}
                    kind="primary"
                    size="large"
                    className={cx("button")}
                  >
                    {block.buttonText}
                  </CosmosButton>
                )}
                {block.outlineButtonUrl && block.outlineButtonText && (
                  <CosmosButton
                    appearance="dark"
                    href={block.outlineButtonUrl}
                    kind="secondary"
                    size="large"
                    className={cx("button")}
                  >
                    {block.outlineButtonText}
                  </CosmosButton>
                )}
              </div>
            </div>
            {block.heroArtwork && (
              <div className={cx("can")}>
                <ContentfulResponsiveImage
                  widthVariants={
                    block.artworkWithStamp ? [290, 230, 200] : [210, 152, 132]
                  }
                  sizes={
                    block.artworkWithStamp
                      ? // matched to display widths defined in `container.css`
                        "(min-width: 1000px) 290px, (min-width: 650px) 230px, 200px"
                      : "(min-width: 1000px) 210px, (min-width: 650px) 152px, 132px"
                  }
                  className={cx("can", {
                    "can--stamp": block.artworkWithStamp,
                  })}
                  asset={block.heroArtwork}
                  alt={block.heroArtwork.description || "Packshot"}
                  loading="eager"
                />
                {block.certificate && (
                  <ContentfulResponsiveImage
                    widthVariants={[90, 60]}
                    sizes="(min-width: 1000px) 90px, 60px"
                    className={cx("certificate")}
                    asset={block.certificate}
                    alt={block.certificate.description}
                    loading="eager"
                  />
                )}
                <DietaryMarks
                  kind="vegetarian"
                  className={cx("dietary-mark-vegetarian")}
                />
              </div>
            )}
            <DietaryMarks
              kind="high-sugar"
              sugarFree={route.includes("red-bull-sugarfree")}
              className={cx("dietary-mark-high-sugar")}
            />
          </div>
        </div>
      </div>

      <div className={cx("video-player")}>
        {/* The player adds padding at the bottom needed to display the page correctly, so we keep the wrapper */}
        {block.videoPlayerId && (
          <div className={cx("video-shadow-background")}>
            <div className={cx("video-wrap")}>
              <div className={cx("video-wrap-inner")}>
                <RbVideo
                  id={block.videoPlayerId}
                  isAutoPlaying={viewportWidth > MOBILE_BREAKPOINT}
                  isLooping={true}
                />
              </div>
            </div>
          </div>
        )}
        {block.videoLegalText && (
          <p className={cx("video-legal-text")}>{block.videoLegalText}</p>
        )}
      </div>
    </div>
  );
};

function scrollBy(id: string): void {
  const MENU_HEIGHT_OFFSET = 80;
  const scrollElement = document.getElementById(id);

  if (scrollElement) {
    window.scrollBy({
      top: scrollElement.getBoundingClientRect().top - MENU_HEIGHT_OFFSET,
      behavior: "smooth",
    });
  }
}
